var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"aQOeQEur2otRKrgwxpIm0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

 import * as Sentry from "@sentry/nextjs";
 Sentry.init({
     dsn: "https://8390381e4da049c480e08bd5c3b9f999@o1264728.ingest.sentry.io/6550075",
     environment: "production",
     replaysSessionSampleRate: 0.1,
     replaysOnErrorSampleRate: 1.0,
     integrations: [new Sentry.Replay()],
 });
